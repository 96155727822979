import React from 'react';

const BeautifulTextWithMessage = ({ title, message }) => {
  return (
    <div className="relative bg-gradient-to-br from-purple-500 to-pink-500 text-white p-8 rounded-lg shadow-lg overflow-hidden">
      <div className="absolute inset-0 bg-opacity-30 bg-black"></div>
      <div className="relative z-10">
        <h1 className="text-4xl md:text-5xl font-extrabold mb-4">{title}</h1>
        <p className="text-lg md:text-xl leading-relaxed">{message}</p>
      </div>
    </div>
  );
};

export default BeautifulTextWithMessage;
