// import React from 'react';

// const EducationalResource = ({ title, imageUrl }) => {
//   return (
//     <div className="max-w-sm mx-auto bg-gradient-to-br from-blue-100 to-blue-200 border border-gray-300 rounded-lg shadow-lg overflow-hidden transform transition-transform duration-500 hover:scale-105 hover:shadow-xl">
//       <div className="relative">
//         <img
//           className="w-full h-48 sm:h-64 object-cover"
//           src={imageUrl}
//           alt={title}
//         />
//         <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-40"></div>
//         <div className="absolute bottom-0 left-0 p-6">
//           <h2 className="text-2xl sm:text-3xl font-extrabold text-white">{title}</h2>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EducationalResource;


import React from 'react';

const EducationalResource = ({ title, imageUrl }) => {
  return (
    <div className="max-w-sm mx-auto bg-gradient-to-br from-blue-100 to-blue-200 border border-gray-300 rounded-lg shadow-lg overflow-hidden transform transition-transform duration-500 hover:scale-105 hover:shadow-xl">
      <div className="relative">
        <img
          className="w-full h-48 sm:h-64 object-cover"
          src={imageUrl}
          alt={title}
        />
      </div>
      <div className="p-4 bg-gradient-to-br from-blue-600 to-blue-800 rounded-b-lg">
        <h2 className="text-xl sm:text-2xl font-extrabold text-white text-center">
          {title}
        </h2>
      </div>
    </div>
  );
};

export default EducationalResource;
