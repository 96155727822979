// import React, { useState } from 'react'
// import logo from '../assets/images/logo.png'
// import { AiFillFileText } from 'react-icons/ai'
// import { FiMenu } from 'react-icons/fi'
// import { Link } from 'react-router-dom'
// import ClassData from '../Class.json'
// import { FaAngleDown } from 'react-icons/fa'
// import { FaAngleRight } from 'react-icons/fa'
// import { AiOutlineClose } from 'react-icons/ai'

// const MobileHeader = () => {
//     const [isOpen, setIsOpen] = useState(false)
//     const [isMenuOpen, setMenuOpen] = useState(false);

//     const toggleMenu = (e) => {
//         e.preventDefault()
//         // setMenuOpen(true);
//         // setTimeout(() => {
//         //   setMenuOpen(false);
//         // }, 2000);
//         if(isMenuOpen=== true){
//             setMenuOpen(false)
//         }else{
//             setMenuOpen(true)
//         }

//     }

//     return (
//         <div>
//             {/* mobile screen */}
//             <div className="mobile-header  bg-[#fff] py-[10px] shadow md:hidden w-[100vw] ">
//                 <div className='container px-4 mx-auto flex justify-between items-center w-[100%]' >
//                     <div className="logo-mobile before:content-[''] after:content-['']">
//                         <a href="/">
//                             <img src={logo} width={150} alt="Logo" />
//                         </a>
//                     </div>

//                     <div className="btn-panel flex justify-center items-center gap-2">
//                        <div>
//                        <a className="before:content-[''] inline-block p-2 text-white rounded-md border bg-[#032759] border-[#032759]" href="/">
//                             <AiFillFileText />
//                         </a>
//                        </div>
//                         <div onClick={toggleMenu}>
//                             {
//                                 isMenuOpen ? <a className="before:content-[''] inline-block p-2 text-white rounded-md border bg-[#032759] border-[#032759]" >
//                                     <AiOutlineClose />
//                                 </a> : <a className="before:content-[''] inline-block p-2 text-white rounded-md border bg-[#032759] border-[##032759]" href="/" >
//                                     <FiMenu />
//                                 </a>
//                             }

//                         </div>
//                     </div>
//                 </div> 
//                 {
//                         isMenuOpen &&
//                         <div className='main-menu w-[100vw] relative flex flex-col z-[9999] opacity-1 transition-all duration-300 ease-linear '>
//                             <ul className='p-0 m-0 flex flex-col list-nonebefore:content[""] after:content[""] shadow-lg bg-gray-400  absolute w-full'>
//                                 <li className='py-8 px-2 inline-block'>
//                                     <Link to="/" className=" font-medium text-base hover:text-gray-600 uppercase font-Barlow ">Home</Link>
//                                 </li>
//                                 <li className=' px-2  ' onMouseEnter={() => setIsOpen(true)}
//                                     onMouseLeave={() => setIsOpen(false)} >
//                                     <Link to="/category/books" className=' font-medium text-base hover:text-gray-600  uppercase font-Barlow  '>Books
//                                         {
//                                             isOpen ? <FaAngleDown className='inline-block before:content-[""]' /> :
//                                                 <FaAngleRight className='inline-block before:content-[""] ' />
//                                         }

//                                         {
//                                             isOpen &&
//                                             <ul className=' '>
//                                                 {
//                                                     ClassData.map(item => {
//                                                         return (
//                                                             <Link to={item.direct}>
//                                                                 <li key={item.id} className=" block px-4 py-1 hover:bg-gray-100 dark:hover:bg-gray-600  dark:hover:text-white">
//                                                                     {item.classCategory}
//                                                                 </li>
//                                                                 <hr />
//                                                             </Link>
//                                                         )

//                                                     })
//                                                 }
//                                             </ul>
//                                         }
//                                     </Link>
//                                 </li>

//                                 <li className='py-8 px-2'>
//                                     <Link to="/contact" className='font-medium text-base hover:text-gray-600  uppercase font-Barlow'>Contact us
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     }
//             </div>
//         </div>
//     )
// }

// export default MobileHeader

import React, { useState } from 'react';
import logo from '../assets/images/logo.png';
import { AiFillFileText } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import ClassData from '../Class.json';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';

const MobileHeader = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <div className="w-full">
            {/* Mobile Header */}
            <div className="bg-gradient-to-r from-white-700 to-blue-700 text-white  shadow-md py-4 px-6 flex justify-between items-center md:hidden">
                <div className="logo">
                    <Link to="/">
                        <img src={logo} width={150} alt="Logo" />
                    </Link>
                </div>
                <div className="flex items-center gap-2">
                    <Link to="/" className="p-2 text-teal-100 bg-gradient-to-r from-blue-500 to-blue-800  rounded-md flex items-center justify-center">
                        <AiFillFileText className="text-lg" />
                    </Link>
                    <button
                        onClick={toggleMenu}
                        className="p-2 text-teal-100 bg-gradient-to-r from-green-700 to-blue-500  rounded-md flex items-center justify-center"
                    >
                        {isMenuOpen ? (
                            <AiOutlineClose className="text-xl" />
                        ) : (
                            <FiMenu className="text-xl" />
                        )}
                    </button>
                </div>
            </div>

            {isMenuOpen && (
                <div className="fixed top-0 left-0 w-full bg-gradient-to-r from-blue-500 to-blue-400  text-white z-50 md:hidden">
                    <ul className="p-4 space-y-4">
                        <li>
                            <Link
                                to="/"
                                className="block text-lg hover:text-teal-300"
                                onClick={() => setMenuOpen(false)}
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <button
                                onClick={() => setSubMenuOpen(!isSubMenuOpen)}
                                className="flex items-center justify-between w-full text-lg hover:text-teal-300"
                            >
                                Books
                                {isSubMenuOpen ? (
                                    <FaAngleDown className="text-lg" />
                                ) : (
                                    <FaAngleRight className="text-lg" />
                                )}
                            </button>
                            {isSubMenuOpen && (
                                <ul className="mt-2 pl-4 space-y-2 bg-gradient-to-r from-red-500 to-red-300  rounded-md">
                                    {ClassData.map(item => (
                                        <li key={item.id}>
                                            <Link
                                                to={item.direct}
                                                className="block py-2 px-4 hover:bg-teal-400 rounded-md"
                                                onClick={() => setMenuOpen(false)}
                                            >
                                                {item.classCategory}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                        <li>
                            <Link
                                to="/contact"
                                className="block text-lg hover:text-teal-300"
                                onClick={() => setMenuOpen(false)}
                            >
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MobileHeader;
