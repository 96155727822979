import React from 'react';
import { useState, useEffect } from 'react';

const DynamicTextAnimation = ({ message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisible((prev) => !prev);
    }, 1000); // Toggle visibility every second

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative bg-gradient-to-r from-blue-400 to-red-400 p-12 rounded-lg shadow-xl overflow-hidden text-center">
      <div className="absolute inset-0 bg-opacity-40 "></div>
      <div className="relative z-10">
        <h1 className={`text-4xl md:text-5xl lg:text-6xl font-extrabold text-white ${visible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000`}>
          {message}
        </h1>
      </div>
    </div>
  );
};

export default DynamicTextAnimation;
