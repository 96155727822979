// import React from 'react'
// import { BsFillTelephoneFill } from 'react-icons/bs'
// import { FiMail } from 'react-icons/fi'
// import { FaAddressCard } from 'react-icons/fa'

// const ContactUsLeft = () => {
//     return (
//         <>
//             <div className="contact-us w-[90%] md:w-[50%]">
//                 <div>
//                     <h3 className='text-[1.8em] md:text-[2.3em] font-bold mb-[20px]'>Contact Us</h3>
//                 </div>

//                 <div className="innergrid flex mb-5 md:flex-row ">
//                     <span className="iconbox w-11 h-11 inline-block bg-[#032759] text-center p-[11px] rounded-lg">
//                         <BsFillTelephoneFill className='before:content-[""] text-xl text-white' />
//                     </span>
//                     <div className='ml-[-35px]'>
//                         <span className='lbl inline-block md:block pl-[55px] text-base mb-0 text-[#6e6e6e]'>
//                             Phone
//                         </span>
//                       <br />  
//                         <a href='tel:1234567890' className='max-lg:text-xs m-0 pl-[55px] font-bold text-black'>+91 88262 26655</a>
//                     </div>
//                 </div>

//                 <div className="innergrid  mb-5 flex">
//                     <span className="iconbox w-11 h-11 inline-block bg-[#032759] text-[#fff] text-center p-[11px] rounded-lg">
//                         <FiMail className='before:content-[""] text-xl text-white' />
//                     </span>
//                     <div className='ml-[-35px]'>
//                         <span className='lbl block pl-[55px] text-base text-[#6e6e6e]'>
//                             Email
//                         </span>
//                         <h4 className='m-0 max-lg:text-xs pl-[55px] sm:font-semibold text-black'>oneducateeducation@gmail.com</h4>
//                     </div>
//                 </div>


//                 <div className="innergrid flex ">
//                     <span className="iconbox w-11 h-11 inline-block bg-[#032759]  text-center p-[11px] rounded-lg">
//                         <FaAddressCard className='before:content-[""] text-xl text-white' />
//                     </span>
//                     <div className='ml-[-35px]'>
//                         <span className='lbl block pl-[55px] text-base text-[#6e6e6e]'>
//                             Address
//                         </span>

//                         <h4 className='m-0 pl-[55px] font-bold text-black'>India - 250002</h4>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default ContactUsLeft


import React from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';
import { FaAddressCard } from 'react-icons/fa';

const ContactUsLeft = () => {
  return (
    <div className="contact-us w-full md:w-1/2 px-4 md:px-8">
      <h3 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">Contact Us</h3>
      
      {/* Phone */}
      <div className="flex items-start mb-6">
        <span className="iconbox w-14 h-14 bg-teal-500 text-center p-3 rounded-full text-white flex items-center justify-center mr-4">
          <BsFillTelephoneFill className="text-2xl" />
        </span>
        <div>
          <span className="block text-lg text-gray-600">Phone</span>
          <a href="tel:1234567890" className="block text-lg font-semibold text-gray-800">+91 xxxxxxxxxx</a>
        </div>
      </div>
      
      {/* Email */}
      <div className="flex items-start mb-6">
        <span className="iconbox w-14 h-14 bg-teal-500 text-center p-3 rounded-full text-white flex items-center justify-center mr-4">
          <FiMail className="text-2xl" />
        </span>
        <div>
          <span className="block text-lg text-gray-600">Email</span>
          <a href="mailto:readerindia@gmail.com" className="block text-lg font-semibold text-gray-800">readerindia@gmail.com</a>
        </div>
      </div>
      
      {/* Address */}
      <div className="flex items-start">
        <span className="iconbox w-14 h-14 bg-teal-500 text-center p-3 rounded-full text-white flex items-center justify-center mr-4">
          <FaAddressCard className="text-2xl" />
        </span>
        <div>
          <span className="block text-lg text-gray-600">Address</span>
          <p className="block text-lg font-semibold text-gray-800">India - 250002</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUsLeft;
