// import React, { useState } from 'react'
// import { FiMail } from 'react-icons/fi'
// import { BiLogoFacebook } from 'react-icons/bi'
// import { BiLogoInstagram } from 'react-icons/bi'
// import { TfiYoutube } from 'react-icons/tfi'
// import { BsFillTelephoneFill } from 'react-icons/bs'
// import { FaAngleDown } from 'react-icons/fa'
// import { FaAngleRight} from 'react-icons/fa'
// import { AiFillFileText } from 'react-icons/ai'
// import ClassData from '../Class.json'
// import logo from '../assets/images/logo.png'
// import { Link } from 'react-router-dom'


// const LargeScreen = () => {
//     const [isOpen, setIsOpen] = useState(false)
//     return (
//         <>
//             <div className="header-section bg-[#032759] h-[100px] z-[999] relative hidden md:block">
//                 <div className="container before:content-[''] c px-4  after:content-['']" >
//                     <div className="row mx-[-15px] before:content-[''] after:content-['']">
//                         <div className="top-head py-[10px] flex justify-between items-center px-[18px] ">
//                             <div className="left-panel hover:cursor-pointer text-white font-sans text-base pt-1 flex gap-2 items-center">
//                                 <FiMail />
//                                 <a href='mailto:oneducateeducation@gmail.com' > 	oneducateeducation@gmail.com</a>
//                             </div>
//                             <div className="social-panel flex gap-1 md:gap-4 items-center text-center">
//                                 <a href="/" className='bg-white w-[30px] h-[30px] relative inline-block text-[#032759] text-lg rounded-[4px] p-[6px] text-center'>
//                                     <BiLogoFacebook />
//                                 </a>
//                                 <a href="/" className='bg-white w-[30px] h-[30px] relative inline-block text-[#032759] text-lg rounded-[4px] p-[6px] text-center'>
//                                     <BiLogoInstagram />
//                                 </a>
//                                 <Link to="https://www.youtube.com/channel/UCq7oyu8O658vpsi_KbNenNg" target='_blank' className='bg-white w-[30px] h-[30px] relative inline-block text-[#032759] text-lg rounded-[4px] p-[6px] text-center '>
//                                     <TfiYoutube />
//                                 </Link>
//                             </div>
//                             <div className="right-panel  ">
//                                 <a href="/" className='font-sans text-white '>
//                                     <BsFillTelephoneFill className="before:content-[''] inline-block text-sm mr-[6px] " />
//                                     <p className='text-base inline-block break-all'>  NEED HELP? +91 88262 26655
//                                     </p>
//                                 </a>
//                             </div>
//                         </div>

//                         {/* main-menu  */}
//                         <div className="main-menu bg-[#fff] shadow-md mt-3 mx-[30px] flex items-center justify-between px-6 ">
//                             <div className="logo-panel max-lg: ml-[-20px]">
//                                 <a href="/">
//                                     <img src={logo} className='' alt="logo" />
//                                 </a>
//                             </div>
//                             <div className="main-panel ml-[-17%] lg:ml-[-25%]">
//                                 <ul className='p-0 m-0 inline-block list-none'>
//                                     <li className='py-8 px-2 inline-block'>
//                                         <Link to="/" className=" font-medium text-base hover:text-gray-600 uppercase font-Barlow ">Home</Link>
//                                     </li>
//                                     <li className='py-8 px-2 inline-block ' onMouseEnter={() => setIsOpen(true)}
//                                         onMouseLeave={() => setIsOpen(false)} >
//                                         <Link to="/category/books" className=' font-medium text-base hover:text-gray-600  uppercase font-Barlow  '>Books
//                                         {
//                                             isOpen ?  <FaAngleDown className='inline-block before:content-[""]  ' />:
//                                             <FaAngleRight className='inline-block before:content-[""] ' />
//                                         }
                                           
//                                             {
//                                                 isOpen && 
//                                                 <ul className='py-2 text-sm  absolute top-36 z-[-1] before:content-[""] after:content-[""] shadow-md  w-[200px] bg-white rounded-lg
//                                                 transition-all duration-1000 ease-in transform '>
//                                                     {
//                                                         ClassData.map((item, id)=> {
//                                                             return (
//                                                                 <Link to={item.direct} key={id} >
//                                                                     <li key={item.id} className=" block px-4 py-1 hover:bg-gray-100 dark:hover:bg-gray-600  dark:hover:text-white">
//                                                                         {item.classCategory}
//                                                                     </li>
//                                                                     <hr />
//                                                                 </Link>
//                                                             )

//                                                         })
//                                                     }
//                                                 </ul>
//                                             }
//                                         </Link>
//                                     </li>

//                                     <li className='py-8 px-2 inline-block'>
//                                         <Link to="/contact" className='font-medium text-base hover:text-gray-600  uppercase font-Barlow'>Contact us
//                                         </Link>
//                                     </li>
//                                 </ul>
//                             </div>
//                             <div className="paper-panel  pr-1">
//                                 <a href="/" className='  btngen inline-block border-none p-4 relative bg-gradient-to-b from-[#144d9d] to-[#032759] pl-7 font-bold font-sans text-white rounded-[5px]'>

//                                     <AiFillFileText size={28} className='before:content-[""] p-[5px] bg-white text-blue-800 absolute left-[-6px] top-[19px] border-2 border-blue-900 rounded-md' />
//                                     Paper Generator
//                                 </a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default LargeScreen


import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMail } from 'react-icons/fi';
import { BiLogoFacebook, BiLogoInstagram } from 'react-icons/bi';
import { TfiYoutube } from 'react-icons/tfi';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { AiFillFileText } from 'react-icons/ai';
import ClassData from '../Class.json';
import logo from '../assets/images/logo.png';

const LargeScreen = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <header className="w-full hidden md:block">
            {/* Top Header */}
            <div className="bg-gradient-to-r from-green-400 to-blue-500 text-white w-full">
                <div className="container mx-auto px-4 py-3 flex flex-col md:flex-row justify-between items-center">
                    {/* Contact Info */}
                    <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6">
                        {/* Email */}
                        <div className="flex items-center space-x-2 text-base">
                            <FiMail className="text-lg" />
                            <a href="mailto: readerindia@gmail.com" className="hover:underline hover:text-gray-200">
                                readerindia@gmail.com
                            </a>
                        </div>
                        {/* Social Media Icons */}
                        <div className="flex items-center space-x-4">
                            <a href="/" className="bg-white text-green-600 p-3 rounded-full transition-transform transform hover:scale-110 hover:bg-gray-200">
                                <BiLogoFacebook className="text-xl" />
                            </a>
                            <a href="/" className="bg-white text-pink-600 p-3 rounded-full transition-transform transform hover:scale-110 hover:bg-gray-200">
                                <BiLogoInstagram className="text-xl" />
                            </a>
                            <a href="https://www.youtube.com/channel/" target="_blank" rel="noopener noreferrer" className="bg-white text-red-600 p-3 rounded-full transition-transform transform hover:scale-110 hover:bg-gray-200">
                                <TfiYoutube className="text-xl" />
                            </a>
                        </div>
                        {/* Phone Number */}
                        <div className="flex items-center space-x-2 text-base">
                            <BsFillTelephoneFill className="text-lg" />
                            <p>
                                <span className="font-semibold">NEED HELP?</span> <span className="block md:inline text-base">+91 xxxxxxxxxx</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Menu */}
            <div className="bg-white shadow-lg w-full">
                <div className="container mx-auto flex items-center justify-between px-6 py-4">
                    {/* Logo */}
                    <Link to="/">
                        <img src={logo} alt="Logo" className="w-36 md:w-40" />
                    </Link>

                    {/* Navigation */}
                    <nav className="flex items-center space-x-6">
                        <ul className="flex items-center space-x-6">
                            <li>
                                <Link
                                    to="/"
                                    className="text-red-800 font-medium hover:text-blue-600 transition-colors"
                                >
                                    Home
                                </Link>
                            </li>
                            <li className="relative">
                                <button
                                    onClick={() => setIsOpen(!isOpen)}
                                    className="flex items-center space-x-1 text-green-800 font-medium hover:text-blue-600 transition-colors"
                                >
                                    <span>Books</span>
                                    {isOpen ? <FaAngleDown /> : <FaAngleRight />}
                                </button>
                                {isOpen && (
                                    <ul className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-lg z-50">
                                        {ClassData.map((item) => (
                                            <li key={item.id} className="border-b border-gray-200">
                                                <Link
                                                    to={item.direct}
                                                    className="block px-4 py-2 text-green-800 hover:bg-gray-100 transition-colors"
                                                >
                                                    {item.classCategory}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                            <li>
                                <Link
                                    to="/contact"
                                    className="text-blue-800 font-medium hover:text-blue-600 transition-colors"
                                >
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                        <a
                            href="/"
                            className="bg-gradient-to-b from-blue-600 to-purple-500 text-white px-4 py-2 rounded-lg flex items-center space-x-2 font-bold hover:opacity-80 transition-opacity"
                        >
                            <AiFillFileText size={24} className="bg-white text-pink-600 p-1 rounded-md" />
                            <span>Paper Generator</span>
                        </a>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default LargeScreen;
