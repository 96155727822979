import React from 'react';
import { BsFillPlayFill } from 'react-icons/bs';
import book from '../assets/images/book.png';

const Main = () => {
  return (
    <div className="relative py-16 px-6 md:px-16 bg-gradient-to-r from-[#fff89a] to-[#dbf7ff] flex flex-col md:flex-row items-center justify-center gap-12 md:gap-24 overflow-hidden">
      {/* Book Image */}
      <div className="hidden md:block max-w-[70%] pt-12 px-4">
        <img
          src={book}
          className="max-h-[80%] shadow-lg rounded-lg transition-transform transform duration-300 hover:scale-105 hover:shadow-2xl"
          alt="Book"
        />
      </div>
      {/* Content Panel */}
      <div className="text-center md:text-left relative z-10">
        <div className="mb-8">
          <p className="text-[1.6em] md:text-[2.1em] lg:text-[2.1em] font-semibold leading-tight text-transparent bg-clip-text bg-gradient-to-r from-[#178a17] to-[#be008f] mb-4">
            Dive into a universe of knowledge with our
            <br />
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#285ff5] to-[#d83a56]">
              expansive collection of
            </span>
          </p>
          <h1 className="text-[2.6em] md:text-[3.2em] lg:text-[3.8em] font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#5f84ff] to-[#ff0936] mb-6">
            READER INDIA
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Main;
