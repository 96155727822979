import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="w-full bg-gradient-to-r from-[#003d34] to-[#00796b] py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between text-center md:text-left">
          <p className="text-white text-lg md:text-xl mb-2 md:mb-0">
            &copy; 2024 <span className="font-bold">Reader India</span>. All Rights Reserved. Designed with passion by
          </p>
          <span>
            <a
              href="/"
              className="text-[#b2dfdb] hover:text-[#80cbc4] transition-colors duration-300 text-lg md:text-xl font-semibold"
            >
              ~Shivanifilms
            </a>
          </span>
        </div>
        <div className="mt-6 text-center">
          <p className="text-[#e0f2f1] text-base mb-4">Connect with us:</p>
          <div className="flex justify-center space-x-6">
            <a
              href="https://facebook.com"
              className="text-[#4267B2] hover:text-[#003d34] text-3xl transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://twitter.com"
              className="text-[#1DA1F2] hover:text-[#003d34] text-3xl transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="https://instagram.com"
              className="text-[#C13584] hover:text-[#003d34] text-3xl transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://linkedin.com"
              className="text-[#0077B5] hover:text-[#003d34] text-3xl transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
